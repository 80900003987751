.hero-section {
  background-color: #663399;
  color: white;
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: left;
}

.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  width: 100%;
}

.hero-text {
  flex: 1;
  padding-right: 20px;
}

.hero-text h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 18px;
  margin-bottom: 30px;
}

.search-bar {
  display: flex;
  margin-bottom: 20px;
}

.search-bar input {
  padding: 10px;
  width: 400px; /* Increased width */
  border: none;
  border-radius: 25px 0 0 25px;
  outline: none;
}

.search-bar button {
  padding: 10px 25px; /* Adjusted padding */
  background-color: #ff5733;
  border: none;
  border-radius: 0 25px 25px 0;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-bar button:hover {
  background-color: #ff2e00;
}

.search-bar i {
  margin-left: -20px;
}

.signup-form {
  background-color: #f5f5f5;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  max-width: 400px;
  width: 100%;
}

.signup-form h2 {
  margin-top: 0;
  color: #663399;
  text-align: center;
}

.signup-form .highlight {
  color: #ff5733;
}

.signup-form form {
  display: flex;
  flex-direction: column;
}

.signup-form input {
  padding: 15px;
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.signup-form button {
  padding: 15px;
  background-color: #663399;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
}

.signup-form button:hover {
  background-color: #4b256e;
  transform: translateY(-2px);
}

.signup-form p {
  font-size: 12px;
  color: #666;
  text-align: center;
}

.signup-form a {
  color: #663399;
  text-decoration: none;
}

.signup-form a:hover {
  text-decoration: underline;
}

/* Responsive styling */
@media (max-width: 768px) {
  .hero-content {
    flex-direction: column;
    align-items: center;
  }

  .hero-text {
    padding-right: 0;
    text-align: center;
  }

  .search-bar input {
    width: 100%;
    border-radius: 25px;
    margin-bottom: 10px;
  }

  .search-bar button {
    border-radius: 25px;
  }

  .signup-form {
    margin-top: 20px;
  }
}
