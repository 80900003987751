.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  background-color: rgba(117, 68, 166, 0.8); /* Semi-transparent violet */
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(56, 6, 117, 0.1);
}

.logo img {
  height: 70px;
  border: 2px solid #754caf; /* Add border */
  border-radius: 10px; /* Round the corners */
  box-shadow: 0 4px 8px rgba(50, 10, 138, 0.1); /* Add shadow */
}

.nav {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 50px;
}

.nav a {
  color: white;
  text-decoration: none;
  margin-left: 10px;  /* Reduced margin to move links to the left */
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.nav a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
  transition: color 1s; /* Adding transition for color with 1 second duration */
}

.nav a:hover {
  color: orange;
}

.toggle-icon {
  display: none;
  font-size: 30px;
  cursor: pointer;
}

/* Responsive styling */
@media (max-width: 768px) {
  .header {
    flex-direction: row;
    justify-content: space-between;
  }

  .logo {
    flex: 1;
    display: flex;
    justify-content: flex-start; /* Left align the logo */
  }

  .nav {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 70px;
    left: 0;
    background-color: rgba(117, 68, 166, 0.9);
    box-shadow: 0 4px 6px rgba(56, 6, 117, 0.1);
    padding: 20px 0;
  }

  .nav.open {
    display: flex;
  }

  .nav a {
    margin: 10px 0;
  }

  .toggle-icon {
    display: block;
    position: absolute;
    top: 20px;
    right: 30px;
  }
}
