.learning-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #28045e;
  color: white;
  padding: 50px;
  border-radius: 10px;
  margin: 20px;
}

.content {
  max-width: 60%;
}

.content h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.content p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.content ul {
  list-style-type: none;
  padding: 0;
}

.content ul li {
  font-size: 16px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px;
}

.content ul li::before {
  content: '✔';
  position: absolute;
  left: 0;
  color: #00ff00; /* Green check mark */
}

.view-courses {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #ffffff;
  color: #663399;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s;
}

.view-courses:hover {
  background-color: #e0e0e0;
}

.certificate {
  max-width: 35%;
}

.certificate img {
  width: 100%;
  border-radius: 10px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .learning-info {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .content {
    max-width: 100%;
    text-align: center;
  }

  .certificate {
    max-width: 100%;
    margin-top: 20px;
  }
}
