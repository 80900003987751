.course-section {
  padding: 20px;
  text-align: center;
}

.course-section h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.course-section p {
  font-size: 16px;
  margin-bottom: 20px;
}

.course-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card {
  background-color: #f5f5f5;
  padding: 20px;
  margin: 10px;
  width: 200px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0,0,0,0.2);
}

.card h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.card p {
  font-size: 14px;
  margin-bottom: 20px;
}

.card button {
  background-color: #ca3808;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.card button:hover {
  background-color: #a82d06;
}

/* Responsive styling */
@media (max-width: 768px) {
  .card {
    width: 100%;
    margin: 10px 0;
  }

  .course-section h2 {
    font-size: 24px;
  }

  .course-section p {
    font-size: 14px;
  }
}
