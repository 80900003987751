.client-feedback {
  text-align: center;
  padding: 50px 20px;
  background-color: white;
}

.client-feedback h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.client-feedback p {
  font-size: 18px;
  margin-bottom: 40px;
}

.feedback-card {
  background: linear-gradient(135deg, #14033f 0%, #360a7e 100%);
  color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-align: left;
  min-height: 200px; /* Adjust height as needed */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid transparent;
  transition: all 0.3s ease;
}

.feedback-card:hover {
  border: 2px solid #ffdd57;
  transform: translateY(-10px);
}

.feedback-card h3 {
  margin-top: 0;
}

.feedback-card h4 {
  margin: 10px 0;
  font-size: 14px;
  color: #ffdd57;
}

.feedback-card .stars {
  font-size: 18px;
  margin: 10px 0;
}

.feedback-card p {
  font-size: 14px;
  color: #f3f3f3;
  margin-bottom: 0;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-dots li button:before {
  color: #663399;
}

.slick-prev:before, .slick-next:before {
  color: #663399;
}

/* Responsive styling */
@media (max-width: 768px) {
  .client-feedback h2 {
    font-size: 28px;
  }

  .client-feedback p {
    font-size: 16px;
  }

  .feedback-card {
    padding: 15px;
    margin: 15px;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }

  .feedback-card h3 {
    font-size: 18px;
  }

  .feedback-card h4 {
    font-size: 12px;
  }

  .feedback-card .stars {
    font-size: 16px;
  }

  .feedback-card p {
    font-size: 12px;
  }
}
